<template lang="pug">
include ../../../configs/template
div.text-left.row
  div.col-12
    +data-info('number', 'sailorDocument.number')
  div.col-12
    +data-info('qualification', 'getDirectoryObject({ value: "courses", id: sailorDocument.course_training})[nameLang]')
  div.col-12
    +data-info('nameInstitution', 'getDirectoryObject({ value: "educationTraining", id: sailorDocument.ntz})[nameLang]')
  div.col-sm-12.col-md-4
    +data-info('dateIssue', 'getDateFormat(sailorDocument.date_start)')
  div.col-sm-12.col-md-4
    +data-info('dateEnd', 'getDateFormat(sailorDocument.date_end)')
  div.col-sm-12.col-md-4
    +data-info('courseDateEnd', 'getDateFormat(sailorDocument.course_date_end)')
  div(v-if="checkAccess('backOffice') || checkAccess('certification', 'viewTypeCertificate')").col-12
    +data-info('type', 'getDirectoryObject({ value: "certificateTypes", id: sailorDocument.certificate_type })[nameLang]')
  div(v-if="sailorDocument.is_international").w-33
    +data-info('havCopyinternational', 'sailorDocument.is_international ? $t("yes") : $t("no")')
  div.col-12
    +data-info('status', 'getDirectoryObject({ id: sailorDocument.status_document, value: "statuses" })[nameLang]')(:class="getStatus(sailorDocument.status_document)")
  div(v-if="checkAccess('crewingManager') && sailorDocument.is_signed").col-12
    v-btn(
      @click="saveDocument"
      :loading="isLoadWithOutSign"
      color="primary").mr-3 {{ $t('saveDoc') }}
    v-btn(
      @click="saveDocumentWithWatermark"
      :loading="isLoadWatermark"
      color="primary").mr-3 {{ $t('saveDocWithWatermark') }}
    v-btn(
      @click="saveDocumentWithSign"
      :loading="isLoadWithSign"
      color="primary") {{ $t('saveDocWithSign') }}
</template>

<script>
import { getDateFormat, getStatus, getFiles } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'SailorCertificationInfo',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getDateFormat,
      checkAccess,
      getStatus,
      isLoadWatermark: false,
      isLoadWithOutSign: false,
      isLoadWithSign: false
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    })
  },
  methods: {
    ...mapActions(['getDocument']),
    async saveDocument () {
      this.isLoadWithOutSign = true
      let body = {
        url: `api/v1/docs/certificate_eti/${this.sailorDocument.id}/`,
        fileName: `Document N ${this.sailorDocument.ntz_number}.pdf`,
        contentType: 'application/pdf'
      }
      await getFiles(body.url, body.fileName, body.contentType)
      this.isLoadWithOutSign = false
    },

    saveDocumentWithSign () {
      this.isLoadWithSign = true
      let body = {
        url: `api/v1/docs/certificate_eti_archive/${this.sailorDocument.id}/`,
        fileName: `Signed Document N ${this.sailorDocument.ntz_number}.zip`,
        contentType: 'application/zip'
      }
      this.getDocument(body).finally(() => { this.isLoadWithSign = false })
    },

    saveDocumentWithWatermark () {
      this.isLoadWatermark = true
      let body = {
        url: `api/v1/docs/certificate_eti_watermark/${this.sailorDocument.id}/`,
        fileName: `Copy of Document N ${this.sailorDocument.ntz_number}`,
        contentType: 'application/pdf'
      }

      this.getDocument(body).finally(() => { this.isLoadWatermark = false })
    }
  }
}
</script>
